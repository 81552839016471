var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-view-wrapper pd-10"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"sectionWrapper",staticClass:"content-view section-wrapper",attrs:{"element-loading-background":_vm.loadingBackground}},[_c('table-title',{on:{"resize":_vm.handleTableTitleResize},scopedSlots:_vm._u([{key:"searchForm",fn:function(){return [_c('el-form',{ref:"searchForm",staticClass:"search-list",attrs:{"model":_vm.searchForm}},[_c('el-form-item',{staticClass:"search-item--1 search-corpName",attrs:{"prop":"companyNo"}},[_c('e6-vr-select',{attrs:{"is-title":"","data":_vm.companyList,"placeholder":"所属主体","title":"所属主体","clearable":"","props":{
                id: 'code',
                label: 'name'
              }},model:{value:(_vm.searchForm.companyNo),callback:function ($$v) {_vm.$set(_vm.searchForm, "companyNo", $$v)},expression:"searchForm.companyNo"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"warehouseName"}},[_c('el-input',{attrs:{"placeholder":"仓库名称","title":"仓库名称"},model:{value:(_vm.searchForm.warehouseName),callback:function ($$v) {_vm.$set(_vm.searchForm, "warehouseName", $$v)},expression:"searchForm.warehouseName"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"materialType"}},[_c('e6-vr-select',{attrs:{"is-title":"","data":_vm.slipperMaterialTypeList,"placeholder":"物料类型","title":"物料类型","clearable":"","props":{
                id: 'codeValue',
                label: 'codeName'
              },"multiple":""},model:{value:(_vm.searchForm.materialType),callback:function ($$v) {_vm.$set(_vm.searchForm, "materialType", $$v)},expression:"searchForm.materialType"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"engineerName"}},[_c('input-plus',{attrs:{"valueType":"Array","placeholder":"工程师","title":"工程师","label":"工程师","clearable":""},model:{value:(_vm.searchForm.engineerName),callback:function ($$v) {_vm.$set(_vm.searchForm, "engineerName", $$v)},expression:"searchForm.engineerName"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"materialNos"}},[_c('remote-search',{attrs:{"clearable":"","multiple":"","placeholder":"物料名称","title":"物料名称"},model:{value:(_vm.searchForm.materialNos),callback:function ($$v) {_vm.$set(_vm.searchForm, "materialNos", $$v)},expression:"searchForm.materialNos"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"materialNo"}},[_c('el-input',{attrs:{"placeholder":"物料编码","title":"物料编码"},model:{value:(_vm.searchForm.materialNo),callback:function ($$v) {_vm.$set(_vm.searchForm, "materialNo", $$v)},expression:"searchForm.materialNo"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"equipNos"}},[_c('input-plus',{attrs:{"valueType":"Array","placeholder":"设备号","title":"设备号","label":"设备号","clearable":""},model:{value:(_vm.searchForm.equipNos),callback:function ($$v) {_vm.$set(_vm.searchForm, "equipNos", $$v)},expression:"searchForm.equipNos"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"havingType"}},[_c('e6-vr-select',{attrs:{"is-title":"","data":_vm.haveTypeList,"placeholder":"持有人类型","title":"持有人类型","clearable":"","props":{
                id: 'codeValue',
                label: 'codeName'
              }},model:{value:(_vm.searchForm.havingType),callback:function ($$v) {_vm.$set(_vm.searchForm, "havingType", $$v)},expression:"searchForm.havingType"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"fixedAssets"}},[_c('e6-vr-select',{attrs:{"is-title":"","data":_vm.fixedAssetsList,"placeholder":"固资/存货","title":"固资/存货","clearable":""},model:{value:(_vm.searchForm.fixedAssets),callback:function ($$v) {_vm.$set(_vm.searchForm, "fixedAssets", $$v)},expression:"searchForm.fixedAssets"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--buttons"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("查询")]),_vm._v(" "),_c('el-button',{staticClass:"reset",on:{"click":_vm.handleReset}},[_vm._v("重置")])],1)],1)]},proxy:true},{key:"operateAction",fn:function(){return [_c('i',{staticClass:"e6-icon-export_fill",attrs:{"title":"导出"},on:{"click":_vm.exportData}})]},proxy:true}])}),_vm._v(" "),_c('section',{staticClass:"table-wrapper"},[_c('el-table',{ref:"tableList",attrs:{"border":"","height":_vm.resizeViewHeight + 'px',"data":_vm.tableData,"highlight-current-row":""}},[_c('el-table-column',{attrs:{"label":"序号","width":"50","fixed":"left","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v("\n              "+_vm._s(scope.$index +
                  1 +
                  (_vm.searchForm.pageIndex - 1) * _vm.searchForm.pageSize)+"\n            ")])]}}])}),_vm._v(" "),_vm._l((_vm.columnData),function(column,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":column.fieldName,"label":column.fieldLabel,"min-width":column.width,"fixed":column.fixed,"align":column.align,"header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [(column.fieldName == 'fileUrl' && row.fileUrl)?_c('span',[_c('el-image',{attrs:{"src":row.fileUrl,"preview-src-list":[row.fileUrl]}})],1):_c('span',[_vm._v(_vm._s(row[column.fieldName]))])]}}],null,true)})})],2)],1),_vm._v(" "),_c('section',{ref:"paginationWrapper",staticClass:"pagination-wrapper fixed-section"},[_c('el-pagination',{attrs:{"page-size":_vm.searchForm.pageSize,"current-page":_vm.searchForm.pageIndex,"page-sizes":_vm.pageSizes,"layout":_vm.layout,"total":_vm.total,"background":""},on:{"update:pageSize":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:currentPage":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"update:current-page":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<!--
 * @Description:工程设备查询
 * @Author: ChenXueLin
 * @Date: 2021-10-18 14:41:03
 * @LastEditTime: 2023-03-14 10:21:42
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item
              class="search-item--1 search-corpName"
              prop="companyNo"
            >
              <e6-vr-select
                is-title
                v-model="searchForm.companyNo"
                :data="companyList"
                placeholder="所属主体"
                title="所属主体"
                clearable
                :props="{
                  id: 'code',
                  label: 'name'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="warehouseName">
              <el-input
                v-model="searchForm.warehouseName"
                placeholder="仓库名称"
                title="仓库名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="materialType">
              <e6-vr-select
                is-title
                v-model="searchForm.materialType"
                :data="slipperMaterialTypeList"
                placeholder="物料类型"
                title="物料类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                multiple
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="engineerName">
              <input-plus
                v-model="searchForm.engineerName"
                valueType="Array"
                placeholder="工程师"
                title="工程师"
                label="工程师"
                clearable
              ></input-plus>
            </el-form-item>
            <el-form-item class="search-item--1" prop="materialNos">
              <remote-search
                v-model="searchForm.materialNos"
                clearable
                multiple
                placeholder="物料名称"
                title="物料名称"
              ></remote-search>
            </el-form-item>
            <el-form-item class="search-item--1" prop="materialNo">
              <el-input
                v-model="searchForm.materialNo"
                placeholder="物料编码"
                title="物料编码"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="equipNos">
              <input-plus
                v-model="searchForm.equipNos"
                valueType="Array"
                placeholder="设备号"
                title="设备号"
                label="设备号"
                clearable
              ></input-plus>
            </el-form-item>
            <el-form-item class="search-item--1" prop="havingType">
              <e6-vr-select
                is-title
                v-model="searchForm.havingType"
                :data="haveTypeList"
                placeholder="持有人类型"
                title="持有人类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="fixedAssets">
              <e6-vr-select
                is-title
                v-model="searchForm.fixedAssets"
                :data="fixedAssetsList"
                placeholder="固资/存货"
                title="固资/存货"
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-export_fill" title="导出" @click="exportData"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          ref="tableList"
        >
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="column.fieldName == 'fileUrl' && row.fileUrl">
                <el-image :src="row.fileUrl" :preview-src-list="[row.fileUrl]">
                  <!-- <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div> -->
                </el-image>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  getEngineerStockList,
  getCompanyList,
  findDownList,
  exportEngineerStockList
} from "@/api";
import inputPlus from "@/components/inputPlus";
import { exportData } from "@/utils/download";
export default {
  name: "engineerEquipList",
  data() {
    return {
      loading: false,
      companyList: [], //公司主体
      slipperMaterialTypeList: [], //物料类型
      haveTypeList: [], //持有者类型
      fixedAssetsList: [
        {
          label: "固资",
          id: 1
        },
        {
          label: "存货",
          id: 0
        }
      ],
      searchForm: {
        companyNo: "", //主体编号
        warehouseName: "", //仓库名称
        havingType: "", //持有者类型
        engineerName: [], //工程师名称
        materialType: [], //物料类型
        materialNos: [], //物料编码
        equipNos: [], //设备号
        fixedAssets: "",
        materialNo: "",
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      columnData: [
        {
          fieldName: "havingType",
          display: true,
          fieldLabel: "持有人",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "enginerName",
          display: true,
          fieldLabel: "工程师",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialName",
          display: true,
          fieldLabel: "物料名称",
          width: 200,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialNo",
          display: true,
          fieldLabel: "物料编码",
          width: 130,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipNo",
          display: true,
          fieldLabel: "设备号",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fixedAssets",
          display: true,
          fieldLabel: "固资/存货",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "warehouseName",
          display: true,
          fieldLabel: "仓库名称",
          width: 200,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fileUrl",
          display: true,
          fieldLabel: "图片",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "uploadTime",
          display: true,
          fieldLabel: "上传时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialType",
          display: true,
          fieldLabel: "类型",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "companyName",
          display: true,
          fieldLabel: "所属主体",
          width: 200,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      queryListAPI: getEngineerStockList
    };
  },
  watch: {},
  components: { inputPlus },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  created() {
    this.queryList();
    this.initData();
  },
  mounted() {
    this.setTableScrollDOM("tableList");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      let promiseList = [
        getCompanyList({}),
        findDownList(["engineerMaterialType", "holderType"])
      ];
      let [res, enumRes] = await Promise.all(promiseList);
      //所属主体
      this.companyList = this.getFreezeResponse(res, {
        path: "data"
      });
      //物料类型
      this.slipperMaterialTypeList = this.getFreezeResponse(enumRes, {
        path: "data.engineerMaterialType"
      });
      this.haveTypeList = this.getFreezeResponse(enumRes, {
        path: "data.holderType"
      });
    },
    //  导出
    exportData() {
      exportData(this, exportEngineerStockList);
    }
  }
};
</script>
<style lang="scss" scoped></style>
